import styled, {keyframes} from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

const topWidgetFooterPhrase = 6;

const animationPhrase = keyframes`
  0% {opacity: 0;}
  8% {opacity: 1;}
  32% {opacity: 1;}
  35% {opacity: 0;}
  100% {opacity: 0;}
`;

export const WidgetFooterPhraseFirst = styled.span`
  position: absolute;
  top: ${scaleDesktop(topWidgetFooterPhrase)};
  right: 0;
  opacity: 0;
  animation-name: ${animationPhrase};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 9s;
  animation-delay: 0;
`;
