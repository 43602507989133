import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

const fontSizeWidgetGameLineWriteTitle2 = 20;
const marginTopGameLineWriteTitle2 = 3;

export const WidgetGameLineWriteTitle2 = styled.span`
  display: block;
  font-size: ${scaleDesktop(fontSizeWidgetGameLineWriteTitle2)};
  font-family: 'Inter-SemiBold';
  color: #fc9426;
  margin-top: ${scaleDesktop(marginTopGameLineWriteTitle2)};
`;
