import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

const widthWidgetFooterLogo = 60;
const heightWidgetFooterLogo = 16;

export const WidgetFooterLogo = styled.span`
  box-sizing: border-box;
  display: block;
  width: ${scaleDesktop(widthWidgetFooterLogo)};
  height: ${scaleDesktop(heightWidgetFooterLogo)};
`;
