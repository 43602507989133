import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  heightFooter?: number
}

const heightWidgetFooter = 36;

export const WidgetTop = styled.div<Props>`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - ${({heightFooter = heightWidgetFooter}) => scaleDesktop(heightFooter)});
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`;
