import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  fontSizeLetter?: number
  marginTop?: number
  maxWidth?: number
}

const fontSizeWidgetGameLineName = 16;
const marginWidgetGameLineName = 5;
const maxWidthWidgetGameLineName = 128;

export const WidgetGameLineName = styled.div<Props>`
  box-sizing: border-box;
  display: block;
  font-size: ${({fontSizeLetter = fontSizeWidgetGameLineName,
    theme}) => scaleDesktop(fontSizeLetter, theme.horizontal)};
  color: #fff;
  margin-top: ${({marginTop = marginWidgetGameLineName, theme}) => scaleDesktop(marginTop, theme.horizontal)};
  max-width: ${({maxWidth = maxWidthWidgetGameLineName, theme}) => scaleDesktop(maxWidth, theme.horizontal)};
  text-align: center;
`;
