import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  fontSizeLetter?: number
  marginBottom?: number
}

const fontSizeWidgetGameLineStatus = 22;
const lineHeightWidgetGameLineStatus = 32;
const shadow1WidgetGameLineStatus = 4;
const shadow2WidgetGameLineStatus = 14;
const marginWidgetGameLineStatus = 8;

interface Props {
  win?: boolean
}

export const WidgetGameLineStatus = styled.span<Props>`
  box-sizing: border-box;
  display: block;
  ${props => props.win
    ? `
  color: #2ec967;
  text-shadow: 0 ${scaleDesktop(shadow1WidgetGameLineStatus, props.theme.horizontal)}
  ${scaleDesktop(shadow2WidgetGameLineStatus, props.theme.horizontal)} rgba(46, 201, 103, .7);
  `
    : `
  color: #d94648;
  text-shadow: 0 ${scaleDesktop(shadow1WidgetGameLineStatus, props.theme.horizontal)}
  ${scaleDesktop(shadow2WidgetGameLineStatus, props.theme.horizontal)} rgba(217, 70, 72, .7);
  `}
  font-size: ${
    ({fontSizeLetter = fontSizeWidgetGameLineStatus, theme}) => scaleDesktop(fontSizeLetter, theme.horizontal)
  };
  line-height: ${({theme}) => scaleDesktop(lineHeightWidgetGameLineStatus, theme.horizontal)};
  margin-bottom: ${
    ({marginBottom = marginWidgetGameLineStatus, theme}) => scaleDesktop(marginBottom, theme.horizontal)
  };
  text-transform: uppercase;
`;
