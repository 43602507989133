import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

const fontSizeWidgetGameLineWriteTitle1 = 20;

export const WidgetGameLineWriteTitle1 = styled.span`
  display: block;
  font-size: ${scaleDesktop(fontSizeWidgetGameLineWriteTitle1)};
  font-family: 'Inter-Regular';
  color: #fff;
`;
