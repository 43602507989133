import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  paddingRightChat?: number
}

const paddingWidgetGameLineWriteChat = 30;

export const WidgetGameLineWriteChat = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: ${({paddingRightChat = paddingWidgetGameLineWriteChat}) => scaleDesktop(paddingRightChat)};
`;
