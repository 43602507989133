import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  fontSizeName?: number
  lineHeightName?: number
  maxWidthName?: number
}

const fontSizeWidgetGameLineName = 18;
const lineHeightWidgetGameLineName = 24;
const maxWidthWidgetGameLineName = 128;

export const WidgetGameAboutHold = styled.div<Props>`
  box-sizing: border-box;
  position: relative;
  font-size: ${({fontSizeName = fontSizeWidgetGameLineName, theme}) => scaleDesktop(fontSizeName, theme.horizontal)};
  line-height: ${({lineHeightName = lineHeightWidgetGameLineName,
    theme}) => scaleDesktop(lineHeightName, theme.horizontal)};
  color: #fff;
  max-width: ${({maxWidthName = maxWidthWidgetGameLineName, theme}) => scaleDesktop(maxWidthName, theme.horizontal)};
  text-align: center;
  text-transform: uppercase;
`;
