import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  widthIndex?: number
  heightIndex?: number
  fontSizeIndex?: number
}

const radiusWidgetGameLineIndex = 4;
const widthWidgetGameLineIndex = 54;
const heightWidgetGameLineIndex = 32;
const borderWidgetGameLineIndex = 1;
const fontSizeWidgetGameLineIndex = 15;

export const WidgetGameLineIndex = styled.span<Props>`
  box-sizing: border-box;
  border-radius: ${({theme}) => scaleDesktop(radiusWidgetGameLineIndex, theme.horizontal)};
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #ffd802;
  width: ${({widthIndex = widthWidgetGameLineIndex, theme}) => scaleDesktop(widthIndex, theme.horizontal)};
  height: ${({heightIndex = heightWidgetGameLineIndex, theme}) => scaleDesktop(heightIndex, theme.horizontal)};
  border: ${({theme}) => scaleDesktop(borderWidgetGameLineIndex, theme.horizontal)} solid #ffd802;
  font-size: ${({fontSizeIndex = fontSizeWidgetGameLineIndex, theme}) => scaleDesktop(fontSizeIndex, theme.horizontal)};
`;
