import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  fontSizeTitle?: number
  letterSpacingTitle?: number
}

const fontSizeWidgetSloganFirstTitle = 29;
const letterSpacingWidgetSloganFirstTitle = 0.58;

export const WidgetSloganFirstTitle = styled.span<Props>`
  box-sizing: border-box;
  display: block;
  font-size: ${({fontSizeTitle = fontSizeWidgetSloganFirstTitle,
    theme}) => scaleDesktop(fontSizeTitle, theme.horizontal)};
  letter-spacing: ${({letterSpacingTitle = letterSpacingWidgetSloganFirstTitle,
        theme}) => scaleDesktop(letterSpacingTitle, theme.horizontal)};
  font-family: 'Oswald-Light';
`;
