import styled from 'styled-components';
import background from 'css-images/background-two-teams-horizontal.jpg';

interface Props {
  nude?: boolean
}

const backgroundWidgetTwoTeamsBodyHorizontal = `url(${background}) no-repeat`;

export const WidgetTwoTeamsBodyHorizontal = styled.div<Props>`
  position: relative;
  background: ${props => props.nude ? 'none' : backgroundWidgetTwoTeamsBodyHorizontal};
  background-size: 100%;
  width: 100%;
  height: 0;
  padding-top: calc(100% / 12);
  overflow: hidden;
`;
