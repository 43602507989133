import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
    paddingLeft?: number
    paddingRight?: number
    alignItems?: boolean
}

const paddingLeftWidgetGame = 24;
const paddingRightWidgetGame = 24;

export const WidgetGame = styled.div<Props>`
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    box-sizing: border-box;
    display: flex;
    align-items: ${props => props.alignItems ? 'normal' : 'center'};
    justify-content: space-between;
    padding-left: ${
        ({paddingLeft = paddingLeftWidgetGame, theme}) => scaleDesktop(paddingLeft, theme.horizontal)
    };
    padding-right: ${
        ({paddingRight = paddingRightWidgetGame, theme}) => scaleDesktop(paddingRight, theme.horizontal)
    };
    z-index: 1;
`;
