import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';
const WidthWidgetGameAboutHoldTitle = 120;
const shadowYWidgetGameAboutHoldTitle = 3;
const shadowSWidgetGameAboutHoldTitle = 7;

export const WidgetGameAboutHoldTitle = styled.span`
    box-sizing: border-box;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${({theme}) => scaleDesktop(WidthWidgetGameAboutHoldTitle, theme.horizontal)};
    text-shadow: 0 ${({theme}) => scaleDesktop(shadowYWidgetGameAboutHoldTitle, theme.horizontal)} 
    ${({theme}) => scaleDesktop(shadowSWidgetGameAboutHoldTitle, theme.horizontal)} black,
    0 ${({theme}) => scaleDesktop(shadowYWidgetGameAboutHoldTitle, theme.horizontal)}
    ${({theme}) => scaleDesktop(shadowSWidgetGameAboutHoldTitle, theme.horizontal)} black,
    0 ${({theme}) => scaleDesktop(shadowYWidgetGameAboutHoldTitle, theme.horizontal)}
    ${({theme}) => scaleDesktop(shadowSWidgetGameAboutHoldTitle, theme.horizontal)} black
`;
