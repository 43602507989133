import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

const sizeWidgetFooterText = 20;
const letterWidgetFooterText = 1;

export const WidgetFooterText = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  height: 100%;
  font-size: ${scaleDesktop(sizeWidgetFooterText)};
  letter-spacing: ${scaleDesktop(letterWidgetFooterText)};
  text-transform: uppercase;
`;
