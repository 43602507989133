import React, {ReactElement, useState, useEffect} from 'react';
import {useParams} from 'react-router';
import {Normalize} from 'components/Normalize/Normalize';
import logo from 'css-images/logo.svg';
import {WidgetBody} from 'components/WidgetBody/WidgetBody';
import {WidgetTop} from 'components/WidgetTop/WidgetTop';
import {WidgetGameList} from 'components/WidgetGame/WidgetGameList';
import {WidgetGame} from 'components/WidgetGame/WidgetGame';
import {WidgetGameLine} from 'components/WidgetGame/WidgetGameLine';
import {WidgetGameLineStatus} from 'components/WidgetGame/WidgetGameLineStatus';
import {WidgetGameLineIndex} from 'components/WidgetGame/WidgetGameLineIndex';
import {WidgetGameLineAbout} from 'components/WidgetGame/WidgetGameLineAbout';
import {WidgetGameLinePhoto} from 'components/WidgetGame/WidgetGameLinePhoto';
import {WidgetGameLinePhotoImage} from 'components/WidgetGame/WidgetGameLinePhotoImage';
import {WidgetGameLineName} from 'components/WidgetGame/WidgetGameLineName';
import {WidgetGameLineWriteChat} from 'components/WidgetGame/WidgetGameLineWriteChat';
import {WidgetGameLineWriteTitle1} from 'components/WidgetGame/WidgetGameLineWriteTitle1';
import {WidgetGameLineWriteTitle2} from 'components/WidgetGame/WidgetGameLineWriteTitle2';
import {WidgetGameAboutHold} from 'components/WidgetGame/WidgetGameAboutHold';
import {WidgetFooter} from 'components/WidgetFooter/WidgetFooter';
import {WidgetFooterLogo} from 'components/WidgetFooter/WidgetFooterLogo';
import {WidgetFooterText} from 'components/WidgetFooter/WidgetFooterText';
import {WidgetFooterPhraseFirst} from 'components/WidgetFooter/WidgetFooterPhraseFirst';
import {WidgetFooterPhraseSecond} from 'components/WidgetFooter/WidgetFooterPhraseSecond';
import {WidgetFooterPhraseThird} from 'components/WidgetFooter/WidgetFooterPhraseThird';
import {useTranslation} from 'react-i18next';
import i18n from 'I18n/i18n';

interface LineInterface {
    banner: number
    coef_1: number
    coef_2: number
    gamer_1: {
        nick: string
        photo: {
            src: string
        }
    }
    gamer_2: {
        nick: string
        photo: {
            src: string
        }
    }
}

const SECONDS = 10000;
const FIFTEEN_SECONDS = 15000;
const COUNT_OF_DECIMAL = 3;

const Widget = (): ReactElement => {
    const params = useParams();

    if (params.locale && i18n.language !== params.locale.toString()) {
        i18n.changeLanguage(params.locale.toString());
    }

    const [lines, setLines] = useState([]);
    const [indexs, setIndexs] = useState(0);
    const {t} = useTranslation();
    const urlParams = `id=${params.id ? params.id : '0'}&${params.game_id ? `game_id=${params.game_id}` : ''}`;
    const url = `${process.env.REACT_APP_API_URL as string}/api/v2/banner?${urlParams}`;

    useEffect(() => {
        const interval = setInterval(() => {
            setIndexs(index => index >= lines.length - 1 ? 0 : index + 1);
        }, SECONDS);

        return () => clearInterval(interval);
    }, [lines]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            })
                .then(response => response.json())
                .then(data => {
                    if (JSON.stringify(lines) !== JSON.stringify(data)) {
                        setLines(data);
                    }
                });
        }, FIFTEEN_SECONDS);

        return () => clearInterval(interval);
    }, [lines]);
    const line = lines[indexs] as LineInterface;
    return <>
        <Normalize/>
        <WidgetBody>
            <WidgetTop>
                <WidgetGameList>
                    {line
                        ? <WidgetGame>
                            <WidgetGameLine>
                                <WidgetGameLineStatus win={true}>WIN</WidgetGameLineStatus>
                                <WidgetGameLineIndex>
                                    {Number(line.banner === 1 ? line.coef_1 : line.coef_2).toFixed(COUNT_OF_DECIMAL)}
                                </WidgetGameLineIndex>
                            </WidgetGameLine>
                            <WidgetGameLineAbout>
                                <WidgetGameLinePhoto>
                                    <WidgetGameLinePhotoImage
                                        src={line.banner === 1 ? line.gamer_1.photo.src : line.gamer_2.photo.src}
                                    />
                                </WidgetGameLinePhoto>
                                <WidgetGameLineName>
                                    {line.banner === 1
                                        ? line.gamer_1.nick
                                        : line.gamer_2.nick
                                    }
                                </WidgetGameLineName>
                            </WidgetGameLineAbout>
                            <WidgetGameLine>
                                <WidgetGameLineStatus win={false}>LOSE</WidgetGameLineStatus>
                                <WidgetGameLineIndex>
                                    {Number(line.banner === 1 ? line.coef_2 : line.coef_1).toFixed(COUNT_OF_DECIMAL)}
                                </WidgetGameLineIndex>
                            </WidgetGameLine>
                        </WidgetGame>
                        : <WidgetGame>
                            <WidgetGameLine hold={true}>
                                <WidgetGameLineStatus win={true}>WIN</WidgetGameLineStatus>
                                <WidgetGameLineIndex/>
                            </WidgetGameLine>
                            <WidgetGameAboutHold>{t('line_coming')}</WidgetGameAboutHold>
                            <WidgetGameLine hold={true}>
                                <WidgetGameLineStatus win={false}>LOSE</WidgetGameLineStatus>
                                <WidgetGameLineIndex/>
                            </WidgetGameLine>
                        </WidgetGame>}
                </WidgetGameList>
                <WidgetGameLineWriteChat>
                    <WidgetGameLineWriteTitle1>{t('message_egb.type')}</WidgetGameLineWriteTitle1>
                    <WidgetGameLineWriteTitle2>!EGB</WidgetGameLineWriteTitle2>
                    <WidgetGameLineWriteTitle1>{t('message_egb.chat')}</WidgetGameLineWriteTitle1>
                </WidgetGameLineWriteChat>
            </WidgetTop>
            <WidgetFooter>
                <WidgetFooterLogo>
                    <img src={logo}/>
                </WidgetFooterLogo>
                <WidgetFooterText>
                    <WidgetFooterPhraseFirst>{t('tired_esports')}</WidgetFooterPhraseFirst>
                    <WidgetFooterPhraseSecond>{t('bet_on_streamers')}</WidgetFooterPhraseSecond>
                    <WidgetFooterPhraseThird>{t('line_site')}</WidgetFooterPhraseThird>
                </WidgetFooterText>
            </WidgetFooter>
        </WidgetBody>
    </>;
};

export default Widget;
