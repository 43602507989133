import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  fontSizeTitle?: number
  letterSpacingTitle?: number
  paddingLeftTitle?: number
  marginTop?: number
}

const fontSizeWidgetSloganSecondTitle = 41;
const letterSpacingWidgetSloganSecondTitle = 0.82;
const paddingLeftWidgetSloganSecondTitle = 31;
const marginTopWidgetSloganSecondTitle = 0;

export const WidgetSloganSecondTitle = styled.span<Props>`
  box-sizing: border-box;
  display: block;
  font-size: ${({fontSizeTitle = fontSizeWidgetSloganSecondTitle,
    theme}) => scaleDesktop(fontSizeTitle, theme.horizontal)};
  letter-spacing: ${({letterSpacingTitle = letterSpacingWidgetSloganSecondTitle,
        theme}) => scaleDesktop(letterSpacingTitle, theme.horizontal)};
  font-family: 'Oswald-SemiBold';
  padding-left: ${({paddingLeftTitle = paddingLeftWidgetSloganSecondTitle,
            theme}) => scaleDesktop(paddingLeftTitle, theme.horizontal)};
  margin-top:  ${({marginTop = marginTopWidgetSloganSecondTitle,
                theme}) => scaleDesktop(marginTop, theme.horizontal)};
`;
