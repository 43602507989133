import React, {ReactElement, useState, useEffect} from 'react';
import {useParams} from 'react-router';
import {ThemeProvider} from 'styled-components';
import {Normalize} from 'components/Normalize/Normalize';
import logoColor from 'css-images/logo-color.svg';
import {WidgetTwoTeamsBodyHorizontal} from 'components/WidgetTwoTeamsBodyHorizontal/WidgetTwoTeamsBodyHorizontal';
import {WidgetTop} from 'components/WidgetTop/WidgetTop';
import {WidgetLogo} from 'components/WidgetLogo/WidgetLogo';
import {WidgetSloganBody} from 'components/WidgetSlogan/WidgetSloganBody';
import {WidgetSloganFirstTitle} from 'components/WidgetSlogan/WidgetSloganFirstTitle';
import {WidgetSloganSecondTitle} from 'components/WidgetSlogan/WidgetSloganSecondTitle';
import {WidgetGameList} from 'components/WidgetGame/WidgetGameList';
import {WidgetGame} from 'components/WidgetGame/WidgetGame';
import {WidgetGameLine} from 'components/WidgetGame/WidgetGameLine';
import {WidgetGameLineIndex} from 'components/WidgetGame/WidgetGameLineIndex';
import {WidgetGameLineAbout} from 'components/WidgetGame/WidgetGameLineAbout';
import {WidgetGameLinePhoto} from 'components/WidgetGame/WidgetGameLinePhoto';
import {WidgetGameLinePhotoImage} from 'components/WidgetGame/WidgetGameLinePhotoImage';
import {WidgetGameAboutHold} from 'components/WidgetGame/WidgetGameAboutHold';
import {WidgetGameAboutHoldTitle} from 'components/WidgetGame/WidgetGameAboutHoldTitle';
import {WidgetGameLineStatus} from 'components/WidgetGame/WidgetGameLineStatus';
import i18n from 'I18n/i18n';
import {useTranslation} from 'react-i18next';

interface LineInterface {
    banner: number
    coef_1: number
    coef_2: number
    gamer_1: {
        nick: string
        photo: {
            src: string
        }
    }
    gamer_2: {
        nick: string
        photo: {
            src: string
        }
    }
}

interface PropsInterface {
    nude: boolean
}

const theme = {horizontal: true};

const SECONDS = 10000;
const FIFTEEN_SECONDS = 15000;
const COUNT_OF_DECIMAL = 3;

const WidgetTwoTeamsHorizontal = (props: PropsInterface): ReactElement => {
    const params = useParams();

    if (params.locale && i18n.language !== params.locale.toString()) {
        i18n.changeLanguage(params.locale.toString());
    }

    const [lines, setLines] = useState([]);
    const [indexs, setIndexs] = useState(0);
    const {t} = useTranslation();
    const urlParams = `id=${params.id ? params.id : '0'}&${params.game_id ? `game_id=${params.game_id}` : ''}`;
    const url = `${process.env.REACT_APP_API_URL as string}/api/v2/banner?${urlParams}`;

    useEffect(() => {
        const interval = setInterval(() => {
            setIndexs(index => index >= lines.length - 1 ? 0 : index + 1);
        }, SECONDS);

        return () => clearInterval(interval);
    }, [lines]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            })
                .then(response => response.json())
                .then(data => {
                    if (JSON.stringify(lines) !== JSON.stringify(data)) {
                        setLines(data);
                    }
                });
        }, FIFTEEN_SECONDS);

        return () => clearInterval(interval);
    }, [lines]);
    const line = lines[indexs] as LineInterface;
    return <ThemeProvider theme={theme}>
        <Normalize/>
        <WidgetTwoTeamsBodyHorizontal nude={props.nude}>
            <WidgetTop heightFooter={0}>
                <WidgetLogo>
                    <img src={logoColor}/>
                </WidgetLogo>
                <WidgetSloganBody width={248}>
                    <WidgetSloganFirstTitle>{t('slogan_title_1_1')}</WidgetSloganFirstTitle>
                    <WidgetSloganSecondTitle>{t('slogan_title_1_2')}</WidgetSloganSecondTitle>
                </WidgetSloganBody>
                <WidgetGameList>
                    {line
                        ? <WidgetGame
                            paddingLeft={100}
                            paddingRight={62}
                        >
                            <WidgetGameLine>
                                <WidgetGameLineStatus win={true}>WIN</WidgetGameLineStatus>
                                <WidgetGameLineIndex>
                                    {Number(line.banner === 1 ? line.coef_1 : line.coef_2).toFixed(COUNT_OF_DECIMAL)}
                                </WidgetGameLineIndex>
                            </WidgetGameLine>
                            <WidgetGameLineAbout paddingLeftRight={30}>
                                <WidgetGameLinePhoto>
                                    <WidgetGameLinePhotoImage
                                        src={line.banner === 1 ? line.gamer_1.photo.src : line.gamer_2.photo.src}
                                    />
                                </WidgetGameLinePhoto>
                            </WidgetGameLineAbout>
                            <WidgetGameLine>
                                <WidgetGameLineStatus win={false}>LOSE</WidgetGameLineStatus>
                                <WidgetGameLineIndex>
                                    {Number(line.banner === 1 ? line.coef_2 : line.coef_1).toFixed(COUNT_OF_DECIMAL)}
                                </WidgetGameLineIndex>
                            </WidgetGameLine>
                        </WidgetGame>
                        : <WidgetGame
                            paddingLeft={100}
                            paddingRight={62}
                        >
                            <WidgetGameLine hold={true}>
                                <WidgetGameLineStatus win={true}>WIN</WidgetGameLineStatus>
                                <WidgetGameLineIndex/>
                            </WidgetGameLine>
                            <WidgetGameAboutHold>
                                <WidgetGameAboutHoldTitle>{t('line_coming')}</WidgetGameAboutHoldTitle>
                            </WidgetGameAboutHold>
                            <WidgetGameLine hold={true}>
                                <WidgetGameLineStatus win={false}>LOSE</WidgetGameLineStatus>
                                <WidgetGameLineIndex/>
                            </WidgetGameLine>
                        </WidgetGame>}
                </WidgetGameList>
                <WidgetSloganBody
                    paddingRight={33}
                    top={-5}
                >
                    <WidgetSloganFirstTitle>{t('slogan_title_2_1')}</WidgetSloganFirstTitle>
                    <WidgetSloganSecondTitle
                        fontSizeTitle={29}
                        letterSpacingTitle={0.58}
                        paddingLeftTitle={20}
                        marginTop={3}
                    >{t('slogan_title_2_2')}</WidgetSloganSecondTitle>
                </WidgetSloganBody>
            </WidgetTop>
        </WidgetTwoTeamsBodyHorizontal>
    </ThemeProvider>;
};

export default WidgetTwoTeamsHorizontal;
