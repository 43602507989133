import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  paddingLeftRight?: number
}

const paddingWidgetGameLineAbout = 16;

export const WidgetGameLineAbout = styled.div<Props>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 ${
    ({paddingLeftRight = paddingWidgetGameLineAbout, theme}) => scaleDesktop(paddingLeftRight, theme.horizontal)
  };
`;
