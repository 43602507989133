import styled from 'styled-components';
import background from 'css-images/background.jpg';
import {scaleDesktop} from 'styled/scaleDesktop';

const radiusWidgetBody = 5;

export const WidgetBody = styled.div`
  border-radius: ${scaleDesktop(radiusWidgetBody)};
  position: relative;
  background: url(${background}) no-repeat;
  background-size: 100%;
  width: 100%;
  height: 0;
  padding-top: calc(100% / 2.5);
  overflow: hidden;
`;
