import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

const widthWidgetLogo = 97;
const heightWidgetLogo = 27;
const marginLeftWidgetLogo = 50;
const marginRightWidgetLogo = 50;

export const WidgetLogo = styled.span`
  box-sizing: border-box;
  display: block;
  width: ${({theme}) => scaleDesktop(widthWidgetLogo, theme.horizontal)};
  height: ${({theme}) => scaleDesktop(heightWidgetLogo, theme.horizontal)};
  margin-left: ${({theme}) => scaleDesktop(marginLeftWidgetLogo, theme.horizontal)};
  margin-right: ${({theme}) => scaleDesktop(marginRightWidgetLogo, theme.horizontal)};
`;
