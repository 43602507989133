import styled from 'styled-components';

export const WidgetGameLinePhotoImage = styled.img`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 3;
`;
