import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  widthPhoto?: number
  heightPhoto?: number
  marginBottom?: number
  opacityBackground?: number
}

const widthWidgetGameLinePhoto = 62;
const heightWidgetGameLinePhoto = 75;
const borderAfterWidgetGameLinePhoto = 80;
const marginBottomWidgetGameLinePhoto = 0;
const opacityBackgroundGameLinePhoto = 0.35;

export const WidgetGameLinePhoto = styled.div<Props>`
  position: relative;
  display: block;
  width: ${({widthPhoto = widthWidgetGameLinePhoto, theme}) => scaleDesktop(widthPhoto, theme.horizontal)};
  height: ${({heightPhoto = heightWidgetGameLinePhoto, theme}) => scaleDesktop(heightPhoto, theme.horizontal)};
  margin-bottom: ${
    ({marginBottom = marginBottomWidgetGameLinePhoto, theme}) => scaleDesktop(marginBottom, theme.horizontal)
  };

  &:after {
    content: "";
    border-radius: ${({theme}) => scaleDesktop(borderAfterWidgetGameLinePhoto, theme.horizontal)};
    display: block;
    position: absolute;
    background-color: #0f0f0f;
    opacity: ${({opacityBackground = opacityBackgroundGameLinePhoto}) => opacityBackground};
    width: 100%;
    height: 0;
    padding-top: 100%;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
`;
