import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

const heightWidgetFooter = 36;
const paddingWidgetFooter = 20;

export const WidgetFooter = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fed631;
  width: 100%;
  height: ${scaleDesktop(heightWidgetFooter)};
  padding: 0 ${scaleDesktop(paddingWidgetFooter)};
  z-index: 1;
`;
