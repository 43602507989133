import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import ruTranslations from 'I18n/locales/ru/translations.json';
import enTranslations from 'I18n/locales/en/translations.json';

const resources = {
    en: {
        translation: enTranslations
    },
    ru: {
        translation: ruTranslations
    }
};

i18n.use(initReactI18next).init({
    interpolation: {
        escapeValue: false
    },
    lng: 'en',
    resources
});

export default i18n;
