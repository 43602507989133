import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  paddingRight?: number
  width?: number
  top?: number
}

const topWidgetSloganBody = -2.2;
const shadow1Text = 4.63;
const shadow2Text = 9.88;
const paddingRightWidgetSloganBody = 0;
const widthWidgetSloganBody = 330;

export const WidgetSloganBody = styled.div<Props>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: ${({top = topWidgetSloganBody, theme}) => scaleDesktop(top, theme.horizontal)};
  color: #fff;
  text-shadow: 0 ${({theme}) => scaleDesktop(shadow1Text, theme.horizontal)} 
  ${({theme}) => scaleDesktop(shadow2Text, theme.horizontal)} rgba(0, 0, 0, .53);
  text-transform: uppercase;
  transform: rotate(-4.492deg);
  width: ${({width = widthWidgetSloganBody, theme}) => scaleDesktop(width, theme.horizontal)};
  padding-right: ${({paddingRight = paddingRightWidgetSloganBody,
    theme}) => scaleDesktop(paddingRight, theme.horizontal)};
`;
