import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

const radiusWidgetСustomizableBody = 5;

export const WidgetСustomizableBody = styled.div`
  border-radius: ${scaleDesktop(radiusWidgetСustomizableBody)};
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(100% / 2.5);
  overflow: hidden;
`;
