import styled from 'styled-components';

interface Props {
    hold?: boolean
}

export const WidgetGameLine = styled.div<Props>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: ${props => props.hold ? '.2' : ''}
`;
